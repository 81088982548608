<template>
  <div class="main-bg-color h-100vh d-flex justify-content-center">
    <div class="shipment-card">
      <div v-if="isLoading">
        <OtherLoading variant="warning" />
      </div>
      <div v-else class="p-3">
        <font-awesome-icon
          @click="
            $router.push(
              `/my-booking/${lang}?sessionId=${sessionId}&telephone=${params.telephone}`
            )
          "
          icon="arrow-left"
          class="text-light mb-2 cursor-pointer"
        />
        <div class="booking-container">
          <div class="header-container">
            <p class="mb-0 text-color-kerry font-weight-bold py-2 px-3">
              {{ detail.trackingNo }}
            </p>
          </div>
          <b-container class="p-3 m-0 detail-container">
            <b-row class="no-gutters">
              <b-col cols="4">
                <span>{{ $t("recipient") }} </span></b-col
              >
              <b-col cols="8"
                ><span>{{ detail.recipientName }}</span>
              </b-col>
            </b-row>
            <b-row class="no-gutters">
              <b-col cols="4">
                <span>{{ $t("telephone") }} </span></b-col
              >
              <b-col cols="8"
                ><span>{{ detail.recipientTelephone }}</span>
              </b-col>
            </b-row>
            <b-row class="no-gutters">
              <b-col cols="4">
                <span>{{ $t("deliveryAddress") }} </span></b-col
              >
              <b-col cols="8"
                ><span>{{ detail.address }}</span>
              </b-col>
            </b-row>
            <b-row class="no-gutters">
              <b-col cols="4">
                <span>{{ $t("deliveryAddress") }} </span></b-col
              >
              <b-col cols="8"
                ><span>{{ detail.bookingDate }}</span>
              </b-col>
            </b-row>
          </b-container>
          <div class="p-3 text-center">
            <div class="text-center image-container">
              <img :src="detail.qrCodeUrl" class="box-img" />
            </div>
            <p>{{ detail.footerTracking }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OtherLoading from "@/components/other/OtherLoading";
export default {
components: {OtherLoading},
name:"MyBookingDetail",
data: function(){
  return {
    lang:"th",
    sessionId:"",
    isLoading: true,
    params:{},
    detail: {}
  }
},
created: async function(){
  const {sessionId,telephone,trackingNo} = this.$route.query
  this.lang = this.$route.params.lang ? this.$route.params.lang : "th"
  this.sessionId = sessionId
  this.params.telephone = telephone
  this.params.trackingNo = trackingNo
  if(sessionId){
      await this.getUserGUID()
      await this.getBookingDetail()
    } else {
      this.$router.push('/session-expired')
  }
},
methods: {
  getUserGUID: async function(){
    const getUserUrl = `${this.$baseUrl}/kerryApi/user/${this.sessionId}`
    const {result,error} = await this.handlePromise(this.$axios.get(getUserUrl))
    if (error) {
      console.log(error)
      return
    }
    if(result.data.result === 1){
      this.params.userGUID = result.data.detail.userGUID
      if(!this.params.userGUID){
        this.$router.push('/session-expired')
      }else if (!result.data.detail.isLogin){
        const redirectUrl = encodeURIComponent(`/my-booking/details/${this.lang}?sessionId=${this.sessionId}&telephone=${this.params.telephone}&trackingNo=${this.params.trackingNo}`);
        this.$router.push(`/login?sessionId=${this.sessionId}&redirectUrl=${redirectUrl}`);
      }
    }
  },
  getBookingDetail: async function(){
    const getBookingUrl = `${this.$baseUrl}/kerryApi/MyBooking/detail`
    const {result,error} = await this.handlePromise(this.$axios.post(getBookingUrl,this.params))
    if (error) {
      console.log(error)
      return
    }
    if(result.data.result === 1){
      this.detail = result.data.detail
      this.isLoading = false
    }
  },
  handlePromise: function(promise) {
    return promise.then(data => {
      return {
        error:null,
        result: data
      }
    })
    .catch(err => {
      return {
        error: err
      }
    })
  }
}
}
</script>

<style lang="scss" scoped>
.header-container {
  border-bottom: 1px solid #bebebe;
}

.detail-container {
  border-bottom: 1px solid #bebebe;
  max-width: 100%;
}

.image-container {
  border: 1px solid #bebebe;
}

.booking-container {
  background-color: #ffffff;
  border-radius: 8px;
  margin-bottom: 2rem;
}

.cursor-pointer {
  cursor: pointer;
}

.box-img {
  width: 50%;
  height: 50%;
}

@media only screen and (max-width: 576px) {
  .box-img {
    width: 100%;
    height: 100%;
  }
}
</style>